<template>
  <div class="min-h-screen flex flex-col">
    <header class="">
      <nav class="container mx-auto px-6 py-4 flex justify-between items-center">
        
        <ul class="flex space-x-4">
          <li><a href="#about" class="text-gray-600 hover:text-gray-800">About</a></li>
          <li><a href="#services" class="text-gray-600 hover:text-gray-800">Services</a></li>
          <li><a href="#contact" class="text-gray-600 hover:text-gray-800">Contact</a></li>
        </ul>
      </nav>
    </header>

    <section class="flex-1 flex items-center justify-center bg-cover bg-hero-pattern"
      style="background-image: url('hero-background.jpg');">
      <div class="text-center">
        <h1 class="text-5xl text-gray-900 font-bold">HolisticDS Chatbot Demo</h1>
        <p class="text-xl text-gray-700 mt-4">Your one-stop solution for data science services.</p>
        <button @click="contactUs"
          class="mt-6 px-6 py-2 bg-blue-600 text-white text-lg font-medium rounded hover:bg-blue-700 focus:outline-none">Contact
          Us</button>
      </div>
    </section>

    <section id="about" class="py-12 bg-white">
      <div class="container mx-auto px-6">
        <h2 class="text-3xl font-bold text-center text-gray-900">About Us</h2>
        <p class="mt-4 text-gray-600 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>
    </section>

    <section id="services" class="py-12 bg-gray-100">
      <div class="container mx-auto px-6">
        <h2 class="text-3xl font-bold text-center text-gray-900">Our Services</h2>
        <div class="mt-6 grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
          <div class="p-6 bg-white rounded-lg shadow-md">
            <h3 class="text-xl font-medium text-gray-900">Service 1</h3>
          </div>
          <div class="p-6 bg-white rounded-lg shadow-md">
            <h3 class="text-xl font-medium text-gray-900">Service 2</h3>
          </div>
          <div class="p-6 bg-white rounded-lg shadow-md">
            <h3 class="text-xl font-medium text-gray-900">Service 3</h3>
          </div>
        </div>
      </div>
    </section>

    <section id="contact" class="py-12 bg-white">
      <div class="container mx-auto px-6">
        <h2 class="text-3xl font-bold text-center text-gray-900">Contact Us</h2>
        <p class="mt-4 text-gray-600 text-center">Fill out the form below or email us at contact@holisticds.com</p>
        <!-- Add your contact form here -->
      </div>
    </section>

    <footer class="bg-gray-800">
      <div class="container mx-auto px-6 py-4">
        <p class="text-sm text-center text-gray-400">© 2023 HolisticDS - All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
